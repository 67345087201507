import { rebexApi } from './baseRebexEndpoints';

import {
  type OutlierAnalysisRequest,
  type OutlierAnalysis,
  type OutlierAnalysisPayload,
  type RecalculateKPIsPayload,
  type TriangulationUpload,
} from '../../types/OutlierAnalysis';
import { type PeerGroup } from '../../types/PeerGroup';

export const peerGroupsExtendedApi = rebexApi.injectEndpoints({
  endpoints: (builder) => ({
    getPeerGroups: builder.query<PeerGroup[], null>({
      query: () => '/peer-groups',
    }),
    getPeerGroup: builder.query<PeerGroup, number>({
      query: (id: number) => `/peer-groups/${id}`,
    }),
    addPeerGroup: builder.mutation<any, Partial<PeerGroup>>({
      query: (peerGroup: PeerGroup) => ({ url: '/peer-groups', method: 'POST', body: peerGroup }),
    }),
    updatePeerGroup: builder.mutation<any, Partial<PeerGroup>>({
      query: (peerGroup: PeerGroup) => ({
        url: `/peer-groups/${peerGroup.id}`,
        method: 'PUT',
        body: peerGroup,
      }),
    }),
    deletePeerGroup: builder.mutation<null, number>({
      query: (id: number) => ({
        url: `/peer-groups/${id}`,
        method: 'DELETE',
      }),
    }),
    getOutlierAnalysis: builder.query<OutlierAnalysis, OutlierAnalysisRequest>({
      query: ({ peerGroupId, moduleName }) => `/peer-groups/${peerGroupId}/outlier-analysis/${moduleName}`,

      // Optional: fix RTK caching issues by customizing the cache key
      serializeQueryArgs: ({ queryArgs }) => {
        return `${queryArgs.peerGroupId}-${queryArgs.moduleName}`;
      },
    }),

    uploadTriangulationFile: builder.mutation<any, TriangulationUpload>({
      query: ({ peerGroupId, file }) => {
        const formData = new FormData();
        formData.append('excel_file', file);

        return {
          url: `/reports/upload-triangulation-file`,
          params: { peer_group_id: peerGroupId },
          method: 'POST',
          body: formData,
          contentType: 'multipart/form-data',
        };
      },
    }),
    recalculateKPIs: builder.mutation<OutlierAnalysis, RecalculateKPIsPayload>({
      query: ({ peerGroupId, moduleName, body }: RecalculateKPIsPayload) => ({
        url: `/peer-groups/outlier-analysis/${peerGroupId}/recalculate-kpis/${moduleName}`,
        method: 'POST',
        body,
      }),
    }),
    saveOutlierAnalysis: builder.mutation<any, OutlierAnalysisPayload>({
      query: (analysis: OutlierAnalysisPayload) => ({
        url: '/peer-groups/outlier-analysis',
        method: 'POST',
        body: analysis,
      }),
    }),
    getPeerGroupsByWorkspaceId: builder.query<PeerGroup[], number>({
      query: (workspaceId: number) => `/peer-groups/workspace/${workspaceId}`,
    }),
  }),
});
