import { Navigate, Routes as ReactRoutes, Route } from 'react-router-dom';

import AccountCreation from '../pages/Account/accountCreation';
import AccountLogin from '../pages/Account/accountLogin';
import Data from '../pages/Redesign/pages/Workspaces/Workspace/Data/Data';
import DataDetails from '../pages/Redesign/pages/Workspaces/Workspace/Data/components/DataDetails';
import DetailedResults from '../pages/Redesign/pages/Workspaces/Workspace/DetailedResults/DetailedResults';
import HowToUse from '../pages/Redesign/pages/Resources/HowToUse/HowToUse';
import KPITable from '../pages/Redesign/pages/PeerGroups/OutlierAnalysis/components/KPITable';
import OktaCallbackPage from '../pages/OktaCallbackPage';
import OutlierAnalysis from '../pages/Redesign/pages/PeerGroups/OutlierAnalysis';
import PeerGroups from '../pages/Redesign/pages/PeerGroups';
import Profile from '../pages/Redesign/pages/Profile/Profile';
// Pages
import ProtectedRoute from '../components/router/ProtectedRoute';
import Redesign from '../pages/Redesign/Redesign';
import RedesignedCreateNewWorkspace from '../pages/Redesign/pages/Workspaces/CreateNewWorkspace';
import RedesignedEditWorkspace from '../pages/Redesign/pages/Workspaces/Workspace/EditWorkspace';
import RedesignedFAQ from '../pages/Redesign/pages/Resources/FAQ/FAQ';
import RedesignedGlossary from '../pages/Redesign/pages/Resources/Glossary/Glossary';
import RedesignedKeyThemesInsights from '../pages/Redesign/pages/Workspaces/Workspace/KeyThemesInsights';
import RedesignedModules from '../pages/Redesign/pages/Workspaces/Workspace/DetailedResults/Modules/Modules';
import RedesignedSubmodules from '../pages/Redesign/pages/Workspaces/Workspace/DetailedResults/Modules/Submodules';
import RedesignedWorkspace from '../pages/Redesign/pages/Workspaces/Workspace/Workspace';
import ReportsPublications from '../pages/Redesign/pages/Workspaces/Workspace/ReportsPublications';
import RequestResetPassword from '../pages/Account/requestResetPassword';
import { ResetPassword } from '../pages/Account/resetPassword';
import RoleCheckAndRedirect from '../pages/RoleCheckAndRedirect';
import { TableauAuthProvider } from '../pages/DataManagementHub/contexts/tableauAuthProvider';
import Users from '../pages/Redesign/pages/Users/Users';
import WorkspaceDataContextProvider from '../context/WorkspaceDataContext';
import WorkspaceWrapper from '../pages/Redesign/pages/Workspaces/components/WorkspaceWrapper';
import Workspaces from '../pages/Redesign/pages/Workspaces/Workspaces';

const RoutesApp = () => {
  return (
    <ReactRoutes>
      <Route path="/" element={<WorkspaceDataContextProvider />}>
        <Route index element={<AccountLogin />} />
        <Route path="create" element={<AccountCreation />} />
        <Route path="authorization-code/callback" element={<OktaCallbackPage />} />
        <Route path="authorization-code/role-check-and-redirect" element={<RoleCheckAndRedirect />} />
        <Route path="request-reset-password" element={<RequestResetPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route element={<ProtectedRoute />}>
          <Route path="app" element={<Redesign />}>
            <Route index element={<Navigate to="/app/workspaces" />} />

            {/* Workspaces */}
            <Route path="workspaces">
              <Route index element={<Workspaces />} />
              <Route element={<WorkspaceWrapper />}>
                {/* Create Workspace */}
                <Route path="create-new-workspace" element={<RedesignedCreateNewWorkspace />} />
                <Route path=":workspaceName/:workspaceId" element={<RedesignedWorkspace />}>
                  <Route index element={<Navigate to="data" />} />
                  {/* Data */}
                  <Route path="data" element={<Data />}>
                    <Route path=":submoduleName/:submoduleId" element={<DataDetails />} />
                  </Route>

                  {/* Detailed Results */}
                  <Route
                    path="detailed-results"
                    element={
                      <TableauAuthProvider>
                        <DetailedResults />
                      </TableauAuthProvider>
                    }
                  >
                    <Route index element={<Navigate to="revenues" />} />
                    <Route path=":moduleName" element={<RedesignedModules />}>
                      <Route path=":submoduleNames" element={<RedesignedSubmodules />} />
                    </Route>
                  </Route>

                  {/* Peer Groups (TBD) */}
                  {/* <Route path="peer-groups" element={<PeerGroupList />} /> */}

                  {/* Key Themes and Insights */}
                  <Route path="key-themes-insights" element={<RedesignedKeyThemesInsights />} />

                  {/* Reports and Publications */}
                  <Route path="reports-publications" element={<ReportsPublications />} />

                  {/* Settings */}
                  <Route path="edit-workspace" element={<RedesignedEditWorkspace />} />
                </Route>
              </Route>
            </Route>

            {/* Peer Groups */}
            <Route path="peer-groups">
              <Route index element={<PeerGroups />} />
              <Route path=":peerGroupId/outlier-analysis" element={<OutlierAnalysis />}>
                <Route index element={<Navigate to="revenues" />} />
                <Route path="revenues" element={<KPITable module="Revenues" />} />
                <Route path="costs" element={<KPITable module="Costs" />} />
                <Route path="employee" element={<KPITable module="Employee" />} />
                <Route path="customers-product" element={<KPITable module="Customers & Product" />} />
                <Route path="physical-channels" element={<KPITable module="Physical Channels" />} />
                <Route path="digital-channels" element={<KPITable module="Digital Channels" />} />
                <Route path="call-center-channels" element={<KPITable module="Call Center Channel" />} />
              </Route>
            </Route>

            {/* Users */}
            <Route path="users" element={<Users />} />

            {/* Resources (Help) */}
            <Route path="resources">
              <Route path="how-to-use" element={<HowToUse />} />
              <Route path="glossary/:submodule?" element={<RedesignedGlossary />} />
              <Route path="faq" element={<RedesignedFAQ />} />
            </Route>

            {/* My Profile */}
            <Route path="my-profile" element={<Profile />} />
          </Route>

          {/* OLD ROUTES
          <Route path="rebex-workspaces/add-bcg-user" element={<AddUser />} />
          <Route path="rebex-workspaces" element={<AdminWorkspace />} />
          <Route path="rebex-workspaces/create-new-workspace" element={<CreateWorkspace />} />
          <Route
            path="rebex-workspaces/:workspaceName/:workspaceId"
            element={
              <TableauAuthProvider>
                <DataDashboard />
              </TableauAuthProvider>
            }
          />
          <Route path="rebex-workspaces/:workspaceName/:workspaceId/edit-workspace" element={<EditWorkspace />} />
          <Route
            path="rebex-workspaces/:workspaceName/:workspaceId/data-collection-team"
            element={<DataModuleAssigner />}
          />
          <Route
            path="rebex-workspaces/:workspaceName/:workspaceId/:moduleName/:moduleId"
            element={<SubModuleManagementHub />}
          />
          <Route
            path="rebex-workspaces/:workspaceName/:workspaceId/:moduleName/:moduleId/:submoduleName/:submoduleId"
            element={<DataTables />}
          />
          <Route
            path="rebex-workspaces/:workspaceName/:workspaceId/insights"
            element={
              <TableauAuthProvider>
                <Insights />
              </TableauAuthProvider>
            }
          >
            <Route index element={<Navigate to="revenues/total-revenue-per-customer" replace />} />
            <Route path=":moduleName" element={<Modules />}>
              <Route index element={<ModulesInitialRoute />} />
              <Route path=":submoduleName" element={<SubModules />} />
            </Route>
          </Route>
          <Route
            path="/rebex-workspaces/:workspaceName/:workspaceId/key-themes-and-insights"
            element={<KeyThemesAndInsights />}
          />
          <Route path="/help" element={<Help />} />
          <Route path="/help/dashboard" element={<HelpDashboard />} />
          <Route path="/help/glossary" element={<Glossary />} />
          <Route path="/help/faqs" element={<FAQ />} />
           */}
        </Route>
        <Route path="/bank-x-data-collection-dashboard" element={<Navigate to="/app/workspaces" replace />}>
          <Route path="data-collection-team" element={<Navigate to="/app/workspaces" replace />} />
        </Route>
        <Route path="/resources" element={<Navigate to="/app/workspaces" replace />} />
      </Route>
    </ReactRoutes>
  );
};

export default RoutesApp;
