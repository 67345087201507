import { MoreVert, PlayCircle, Search, Upload } from '@mui/icons-material';
import {
  Autocomplete,
  Backdrop,
  Box,
  Chip,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AppCircularProgress from '../../../../../components/materials/loading/AppCircularProgress';
import { formatDate } from '../../../../../utils/dateUtils';
import {
  useGetAllWorkspacesQuery,
  useGetPeerGroupsQuery,
  useUploadTriangulationFileMutation,
} from '../../../../../utils/redux/api';
import { type PeerGroup } from '../../../../../utils/types/PeerGroup';
import { ToastContext } from '../../../../../context/toastContext';

type PeerGroupRecord = PeerGroup & {
  primaryClient: string;
  clients: number;
  formattedCountries: string;
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  let x: any = a[orderBy];
  let y: any = b[orderBy];
  if (typeof x === 'string' && typeof y === 'string') {
    x = x.trim().toLowerCase();
    y = y.trim().toLowerCase();
  }

  if (y < x) {
    return -1;
  }
  if (y > x) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(order: Order, orderBy: Key): (a: any, b: any) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

type Order = 'asc' | 'desc';

interface TableHeading {
  id: keyof PeerGroupRecord;
  label: string;
}

const tableHeads: readonly TableHeading[] = [
  { id: 'name', label: 'Peer Group Name' },
  { id: 'primaryClient', label: 'Primary Client' },
  // { id: 'clients', label: 'Clients' },
  { id: 'base_year', label: 'Base Year' },
  { id: 'countries', label: 'Countries' },
  { id: 'created_at', label: 'Date Created' },
  { id: 'updated_at', label: 'Last Update' },
  { id: 'status', label: 'Status' },
];

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof PeerGroupRecord) => void;
  order: Order;
  orderBy: string;
  isWorkspaceView: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { palette } = useTheme();

  const { order, orderBy, onRequestSort, isWorkspaceView } = props;
  const createSortHandler = (property: keyof PeerGroupRecord) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {tableHeads.map((head) => (
          <TableCell
            key={head.id}
            sortDirection={orderBy === head.id ? order : false}
            sx={
              orderBy === head.id
                ? {
                    backgroundColor: palette.green[50],
                  }
                : null
            }
          >
            <TableSortLabel
              active={orderBy === head.id}
              direction={orderBy === head.id ? order : 'asc'}
              onClick={createSortHandler(head.id)}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                color: palette.green[500],
                '&.Mui-active': {
                  color: palette.green[500],
                  backgroundColor: palette.green[50],
                },
                svg: {
                  color: `${palette.green[500]} !important`,
                },
              }}
            >
              <span>{head.label}</span>
              {orderBy === head.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {!isWorkspaceView ? <TableCell width="125px"></TableCell> : null}
      </TableRow>
    </TableHead>
  );
}

interface PeerGroupListProps {
  shouldRefetch?: boolean;
  setRefetch?: (value: boolean) => void;
  setSelectedRecord?: (value: PeerGroup | null) => void;
  setIsEditModalOpen?: (value: boolean) => void;
  setIsDuplicateModalOpen?: (value: boolean) => void;
  setIsDeleteModalOpen?: (value: boolean) => void;
  setIsDownloadModalOpen?: (value: boolean) => void;
}

const PeerGroupList: React.FC<PeerGroupListProps> = ({
  shouldRefetch = false,
  setRefetch,
  setSelectedRecord,
  setIsEditModalOpen,
  setIsDuplicateModalOpen,
  setIsDeleteModalOpen,
  setIsDownloadModalOpen,
}) => {
  const { palette } = useTheme();
  const { handleOpenToast } = useContext(ToastContext);
  const navigate = useNavigate();
  const { workspaceId } = useParams();
  const isWorkspaceView = workspaceId !== null && workspaceId !== undefined;
  const { data: allWorkspaces } = useGetAllWorkspacesQuery(null);
  const { data: peerGroupData, isLoading, refetch: peerGroupsRefetch } = useGetPeerGroupsQuery(null);

  // Sorting
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof PeerGroupRecord>('created_at');
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof PeerGroupRecord) => {
    const isAsc = orderBy === property && order === 'desc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Filters
  const [banks, setBanks] = useState<Array<{ id: number; name: string }>>([]);
  const [baseYears, setBaseYears] = useState<number[]>([]);
  const statuses = ['analysis_ongoing', 'analysis_completed', 'triangulated'];
  const [search, setSearch] = useState<string>('');
  const [selectedBank, setSelectedBank] = useState<{ id: number; name: string } | null>(null);
  const [selectedBaseYear, setSelectedBaseYear] = useState<number | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);

  const StyledMenuItem = styled(MenuItem)(() => ({
    padding: '16px 12px',
    lineHeight: '24px',
  }));

  const DangerMenuItem = styled(StyledMenuItem)(() => ({
    color: palette.red[400],
    backgroundColor: palette.red[200],
  }));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>, peerGroup: PeerGroup) => {
    setAnchorEl(event.currentTarget);
    setSelectedRecord!({ ...peerGroup });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getProperStatusLabel = (status: string) => {
    const splittedString = status.split('_');
    const properString = splittedString.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    return properString.join(' ');
  };

  const rawData: PeerGroupRecord[] = React.useMemo(() => {
    if (peerGroupData !== null && peerGroupData !== undefined) {
      const data = isWorkspaceView
        ? peerGroupData.filter((peerGroup: PeerGroup) => peerGroup.workspace_id === Number(workspaceId))
        : peerGroupData;
      const formattedData: PeerGroupRecord[] = data.map(
        (peerGroup: PeerGroup) =>
          ({
            ...peerGroup,
            primaryClient: allWorkspaces?.find((workspace) => workspace.workspace_id === peerGroup.workspace_id)
              ?.workspace_name,
            clients: peerGroup.members !== null && peerGroup.members !== undefined ? peerGroup.members.length : 0,
            formattedCountries: peerGroup.countries.split(',').join(', '),
          }) as unknown as PeerGroupRecord,
      );

      const primaryClients: string[] = [];
      const bankArray: Array<{ id: number; name: string }> = [];
      const baseYearArray: number[] = [];
      for (const record of formattedData) {
        if (!primaryClients.includes(record.primaryClient)) {
          primaryClients.push(record.primaryClient);
          bankArray.push({ id: record.workspace_id, name: record.primaryClient });
        }
        if (!baseYearArray.includes(record.base_year)) {
          baseYearArray.push(record.base_year);
        }
      }
      setBanks(bankArray.sort((a, b) => (a.name > b.name ? 1 : -1)));
      setBaseYears(baseYearArray.sort((a, b) => (a > b ? 1 : -1)));

      return formattedData;
    } else {
      return [];
    }
  }, [peerGroupData, allWorkspaces, isWorkspaceView, workspaceId]);

  const filteredData = React.useMemo(
    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    () =>
      [...rawData]
        .filter((row) => row.name.toLowerCase().includes(search.toLowerCase()))
        .filter((row) => (selectedBank !== null ? row.workspace_id === selectedBank.id : true))
        .filter((row) => (selectedBaseYear !== null ? row.base_year === selectedBaseYear : true))
        .filter((row) => (selectedStatus !== null ? row.status === selectedStatus : true))
        .sort(getComparator(order, orderBy)),
    [rawData, search, selectedBank, selectedBaseYear, selectedStatus, order, orderBy],
  );

  useEffect(() => {
    if (shouldRefetch) {
      void peerGroupsRefetch();
      if (setRefetch !== undefined) {
        setRefetch(false);
      }
    }
  }, [shouldRefetch, peerGroupsRefetch, setRefetch]);

  const handleOutlierAnalysisClick = (peerGroup: PeerGroupRecord) => {
    navigate(`/app/peer-groups/${peerGroup.id}/outlier-analysis`, {
      state: { peerGroupName: peerGroup.name }, // Passing Peer Group Name
    });
  };

  const fileInput = useRef<HTMLInputElement>(null);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [uploadPeerGroup, setUploadPeerGroup] = useState<PeerGroup | null>(null);
  const [uploadFile] = useUploadTriangulationFileMutation();

  const handleUpload = (file: File | null) => {
    if (file !== null && uploadPeerGroup !== null) {
      setUploadLoading(true);

      uploadFile({ peerGroupId: uploadPeerGroup.id, file })
        .then(() => {
          setUploadLoading(false);
          handleOpenToast({ message: 'File uploaded successfully', severity: 'success' });
          void peerGroupsRefetch();
        })
        .catch(() => {
          setUploadLoading(false);
          handleOpenToast({ message: 'Error uploading file', severity: 'error' });
        });
    }
  };

  return (
    <Box
      sx={{
        border: '1px',
        borderRadius: '8px',
        padding: '24px 32px',
        gap: '24px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: palette.gray.white,
        borderColor: palette.gray[300],
      }}
      border={1}
    >
      <Box display="flex" flexDirection="row" sx={{ gap: 3 }} alignItems={'center'}>
        <FormControl fullWidth sx={{ width: '280px' }}>
          <InputLabel htmlFor="outlined-adornment-amount">Search a Peer Group Name</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            endAdornment={
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            }
            label="Search a Peer Group Name"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </FormControl>

        <Autocomplete
          disablePortal
          value={selectedBank}
          options={banks.sort((a, b) => (a.name > b.name ? 1 : -1))}
          getOptionKey={(option) => option.id}
          getOptionLabel={(option) => option.name}
          onChange={(e, selected) => {
            setSelectedBank(selected);
          }}
          renderInput={(params) => <TextField {...params} label="Select a Bank" />}
          sx={{ width: '210px' }}
        />

        <Autocomplete
          disablePortal
          value={selectedBaseYear}
          options={baseYears.sort((a, b) => (a > b ? 1 : -1))}
          getOptionLabel={(option) => option.toString()}
          onChange={(e, selected) => {
            setSelectedBaseYear(selected);
          }}
          renderInput={(params) => <TextField {...params} label="Filter by Base Year" />}
          sx={{ width: '240px' }}
        />

        <Autocomplete
          disablePortal
          value={selectedStatus}
          options={statuses}
          getOptionLabel={(option) => getProperStatusLabel(option)}
          onChange={(e, selected) => {
            setSelectedStatus(selected);
          }}
          renderInput={(params) => <TextField {...params} label="Filter by Status" />}
          sx={{ width: '240px' }}
        />
      </Box>

      {isLoading || uploadLoading ? (
        <Backdrop open>
          <AppCircularProgress />
        </Backdrop>
      ) : (
        <TableContainer>
          <Table>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              isWorkspaceView={isWorkspaceView}
            />
            <TableBody>
              {filteredData.length > 0 ? (
                filteredData.map((peerGroup: PeerGroupRecord) => (
                  <TableRow
                    key={peerGroup.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      td: { maxWidth: '150px' },
                      'td>div': { whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' },
                    }}
                  >
                    <TableCell
                      sx={
                        orderBy === 'name'
                          ? {
                              backgroundColor: palette.green[50],
                              color: palette.green[500],
                            }
                          : null
                      }
                    >
                      <Tooltip title={peerGroup.name}>
                        <div>{peerGroup.name}</div>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      sx={
                        orderBy === 'primaryClient'
                          ? {
                              backgroundColor: palette.green[50],
                              color: palette.green[500],
                            }
                          : null
                      }
                    >
                      {peerGroup.primaryClient}
                    </TableCell>
                    {/* <TableCell>
                  {peerGroup.members !== null && peerGroup.members !== undefined ? peerGroup.members.length : 0}
                </TableCell> */}
                    <TableCell
                      sx={
                        orderBy === 'base_year'
                          ? {
                              backgroundColor: palette.green[50],
                              color: palette.green[500],
                            }
                          : null
                      }
                    >
                      {peerGroup.base_year}
                    </TableCell>
                    {!isWorkspaceView ? (
                      <TableCell
                        sx={
                          orderBy === 'formattedCountries'
                            ? {
                                backgroundColor: palette.green[50],
                                color: palette.green[500],
                              }
                            : null
                        }
                      >
                        <Tooltip title={peerGroup.formattedCountries}>
                          <div>{peerGroup.formattedCountries}</div>
                        </Tooltip>
                      </TableCell>
                    ) : null}
                    <TableCell
                      sx={
                        orderBy === 'created_at'
                          ? {
                              backgroundColor: palette.green[50],
                              color: palette.green[500],
                            }
                          : null
                      }
                    >
                      {formatDate(peerGroup.created_at ?? '')}
                    </TableCell>
                    {/* <TableCell>{peerGroup.}</TableCell> */}
                    <TableCell
                      sx={
                        orderBy === 'updated_at'
                          ? {
                              backgroundColor: palette.green[50],
                              color: palette.green[500],
                            }
                          : null
                      }
                    >
                      {formatDate(peerGroup.updated_at ?? '')}
                    </TableCell>
                    <TableCell
                      sx={
                        orderBy === 'status'
                          ? {
                              backgroundColor: palette.green[50],
                              color: palette.green[500],
                            }
                          : null
                      }
                    >
                      <Chip
                        label={getProperStatusLabel(peerGroup.status ?? '')}
                        sx={{
                          height: '20px',
                          color: palette.green[700],
                          backgroundColor: palette.green[200],
                          borderRadius: '8px',
                        }}
                      />
                    </TableCell>
                    {!isWorkspaceView ? (
                      <TableCell>
                        <Box display="flex" justifyContent="space-between">
                          <Box>
                            <Tooltip title="Start Analysis">
                              <span>
                                <IconButton
                                  aria-label="outlier-analysis"
                                  size="small"
                                  sx={{ padding: 0 }}
                                  onClick={() => {
                                    handleOutlierAnalysisClick(peerGroup);
                                  }}
                                >
                                  <PlayCircle
                                    htmlColor={palette.green[500]}
                                    sx={{ margin: '8px', fontSize: '16px' }}
                                  />
                                </IconButton>
                              </span>
                            </Tooltip>
                            <Tooltip title="Upload File">
                              <span>
                                <IconButton
                                  aria-label="upload-file"
                                  size="small"
                                  sx={{ padding: 0 }}
                                  onClick={() => {
                                    setUploadPeerGroup({ ...peerGroup });
                                    fileInput.current?.click();
                                  }}
                                >
                                  <Upload htmlColor={palette.green[500]} sx={{ margin: '8px', fontSize: '16px' }} />
                                </IconButton>
                              </span>
                            </Tooltip>
                            <input
                              type="file"
                              name="triangulation-file"
                              accept=".xlsx"
                              ref={fileInput}
                              onChange={(e) => {
                                const input: HTMLInputElement = e.target as HTMLInputElement;
                                handleUpload(input.files !== null ? input.files[0] : null);
                              }}
                              style={{ display: 'none' }}
                            />
                          </Box>
                          <Divider orientation="vertical" flexItem color={palette.gray[300]} />
                          <IconButton
                            aria-controls={open ? `actions-menu` : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            size="small"
                            onClick={(event) => {
                              handleClick(event, peerGroup);
                            }}
                            sx={{ padding: 0 }}
                          >
                            <MoreVert htmlColor={palette.green[500]} sx={{ margin: '8px', fontSize: '16px' }} />
                          </IconButton>
                        </Box>
                      </TableCell>
                    ) : null}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    No records found
                  </TableCell>
                </TableRow>
              )}
              <Menu
                id="actions-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                MenuListProps={{
                  sx: { padding: 0 },
                }}
              >
                <StyledMenuItem
                  onClick={() => {
                    setIsEditModalOpen!(true);
                  }}
                >
                  Edit Peer Group
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => {
                    setIsDownloadModalOpen!(true);
                  }}
                >
                  Download Group Files
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={() => {
                    setIsDuplicateModalOpen!(true);
                  }}
                >
                  Duplicate Group
                </StyledMenuItem>
                <DangerMenuItem
                  onClick={() => {
                    setIsDeleteModalOpen!(true);
                  }}
                >
                  Delete Group
                </DangerMenuItem>
              </Menu>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default PeerGroupList;
