import {
  type BaseQueryFn,
  createApi,
  type FetchArgs,
  fetchBaseQuery,
  type FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { type FlatFileUpload } from '../../types/FlatFile';
import { type TableauTrustedTicketResponse } from '../../types/TableauView';
import { type WorkspaceModules } from '../../types/WorkspaceModules';
import { clearAuth } from '../authSlice';

// Get environment variables.
const baseUrl: string = process.env.REACT_APP_REBEX_API_HOST!;

const baseQueryWithAuthExpiration: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as any).auth.accessToken;
      if (token?.length > 0 && (headers.get('Authorization') === '' || headers.get('Authorization') === null)) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  });

  const result = await baseQuery(args, api, extraOptions);

  if (result.error !== undefined && result.error.status === 401) {
    api.dispatch(clearAuth());
  }

  return result;
};

export const rebexApi = createApi({
  reducerPath: 'rebexApi',
  baseQuery: baseQueryWithAuthExpiration,
  endpoints: (builder) => ({
    getModulesByWorkspace: builder.query<WorkspaceModules, number>({
      query: (workspaceId: number) => `/modules/workspace/${workspaceId}`,
    }),
    uploadFlatFile: builder.mutation<FlatFileUpload, Partial<FlatFileUpload>>({
      query: (flatfile: FlatFileUpload) => ({
        url: '/flatfile/upload',
        method: 'POST',
        body: flatfile,
      }),
    }),
    retrieveTableauTrustedTicket: builder.query<TableauTrustedTicketResponse, number>({
      query: (workspaceId: number) => {
        return {
          url: `/tableau/trusted/${workspaceId}`,
          method: 'GET',
        };
      },
    }),

    flatFileEmailNotification: builder.query<WorkspaceModules, string>({
      query: (workspaceName) => `/workspaces/flatfile-file-upload-notification/${workspaceName}`, // Ensure correct API URL format
    }),
  }),
});
