import React, { useEffect, useRef, useState } from 'react';

import { Box } from '@mui/material';
import TableauEmbed from '../../../../../../../../../components/tableau/TableauEmbed';
import kpiNameToTableauViewMapper from '../constants/KpiNameToTableauViewNameMapper';
import { useWorkspaceDataContext } from '../../../../../../../../../context/WorkspaceDataContext';

interface ChartBoxProps {
  submoduleName: string;
}

const ChartBox: React.FC<ChartBoxProps> = ({ submoduleName }) => {
  const [viewUrl, setViewUrl] = useState<string>('');
  const prevWorkspaceName = useRef('');
  const { activeWorkspace: workspace } = useWorkspaceDataContext();

  useEffect(() => {
    if (workspace?.workspace_name !== prevWorkspaceName.current) {
      const tableauHost = process.env.REACT_APP_TABLEAU_SERVER_HOST;
      const site = process.env.REACT_APP_TABLEAU_SERVER_SITE;
      const workbook = process.env.REACT_APP_TABLEAU_SERVER_WORKBOOK;
      const viewName: string | null = kpiNameToTableauViewMapper(submoduleName);
      let url: string | null = `${tableauHost}`;
      url =
        viewName !== null
          ? `${url}/t/${site}/views/${workbook}/${viewName}?:embed=y&:showVizHome=no&:tabs=no${workspace?.workspace_name.length !== undefined && workspace?.workspace_name.length > 0 ? `&workspace_name=${workspace?.workspace_name}` : ''}`
          : null;
      console.log('url: ', url);
      setViewUrl(url!);

      prevWorkspaceName.current = workspace?.workspace_name ?? '';
    }
  }, [submoduleName, workspace?.workspace_name]);

  return (
    <Box
      sx={{
        borderRadius: '8px',
        position: 'relative',
        height: '100vh',
      }}
    >
      <TableauEmbed url={viewUrl}></TableauEmbed>
    </Box>
  );
};

export default ChartBox;
