import api from '@flatfile/api';
import { recordHook } from '@flatfile/plugin-record-hook';
import { Space, useFlatfile, useListener } from '@flatfile/react';
import { type ButtonProps } from '@mui/material';
import React, { useContext } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import RedesignAppButton from '../../../../../../../../components/materials/actions/RedesignAppButton';
import { ToastContext } from '../../../../../../../../context/toastContext';
import { useWorkspaceDataContext } from '../../../../../../../../context/WorkspaceDataContext';
import {
  useUploadFlatFileMutation,
  useLazyFlatFileEmailNotificationQuery,
} from '../../../../../../../../utils/redux/api';

import validateFields from '../../../../../../../SubModule/components/validations/validateFields';
import { useAppSelector } from '../../../../../../../../utils/hooks/useAppSelector';

interface FlatfilePortalProps {
  spaceId?: string;
  buttonProps: ButtonProps;
}

const FlatfilePortal: React.FC<FlatfilePortalProps> = ({ spaceId, buttonProps }) => {
  const { openPortal } = useFlatfile();
  const { workspaceId } = useParams();
  const { setFlatFileData, refetchSubmodule } = useWorkspaceDataContext();
  const { handleOpenToast } = useContext(ToastContext);
  const [uploadFlatFile] = useUploadFlatFileMutation();
  const [fetchEmailNotification, { data, isLoading, error }] = useLazyFlatFileEmailNotificationQuery();
  const { refetchSubmodules } = useOutletContext<{ refetchSubmodules: () => Promise<void> }>();

  const userDetails = useAppSelector((state) => state.auth.userDetails);

  const isAdmin = userDetails.roles?.[0] === 'admin';

  useListener((listener) => {
    listener.use(
      recordHook('revenue-data', (record) => {
        validateFields(record, 'Revenue Data');
        return record;
      }),
    );

    listener.use(
      recordHook('opex-data', (record) => {
        validateFields(record, 'Opex Data');
        return record;
      }),
    );

    listener.use(
      recordHook('employee-data', (record) => {
        validateFields(record, 'Employee(HR) Data');
        const fullTime = record.get('fte');
        if (Number(fullTime) < 0 || Number(fullTime) > 1) {
          record.addError('fte', 'This field must be between 0 and 1.');
        }
        const baseSalary = record.get('BASE_SALARY');
        const variablePay = record.get('VARIABLE_COMMISSION');
        const benefits = record.get('BENEFITS');
        const totalCompensation = record.get('TOTAL_FULL_LOADED_COMPENSATION');
        record.addComment('BASE_SALARY', 'This value must be in USD.');
        record.addComment('VARIABLE_COMMISSION', 'This value must be in USD.');
        record.addComment('BENEFITS', 'This value must be in USD.');
        record.addComment('TOTAL_FULL_LOADED_COMPENSATION', 'This value must be in USD.');

        const total = Number(baseSalary) + Number(variablePay) + Number(benefits);

        if (total !== Number(totalCompensation)) {
          record.addError(
            'totalCompensation',
            'This value must be the sum of baseSalary, variableCommission, and benefits.',
          );
        }

        return record;
      }),
    );

    listener.use(
      recordHook('customer-data', (record) => {
        validateFields(record, 'Product Data');

        const averageBalance = record.get('12_MONTH_AVERAGE_BALANCE');
        if (Number(averageBalance) <= 0) {
          record.addError('12_MONTH_AVERAGE_BALANCE', 'This field must be greater than 0.');
        }

        const age = record.get('CUSTOMER_AGE');
        if (Number(age) < 0 || Number(age) > 150) {
          record.addError('CUSTOMER_AGE', 'Invalid Age, this field must be between 0 and 80');
        }

        return record;
      }),
    );

    listener.filter({ job: 'workbook:submitActionFg' }, (configure) => {
      configure.on('job:ready', async (event) => {
        const { jobId, workbookId } = event.context;

        const workbook = await api.workbooks.get(workbookId as string, {});
        const sheetId = workbook?.data?.sheets?.[0]?.id;
        const records = await api.records.get(sheetId!, {});

        try {
          await api.jobs.ack(jobId as string, {
            info: 'Getting started.',
            progress: 10,
          });

          await uploadFlatFile({
            sheet_id: sheetId!,
            access_token: event._accessToken!,
            workspace_id: Number(workspaceId!),
            sheet_slug: workbook.data.sheets![0].slug,
          }).unwrap();

          await api.jobs.complete(jobId as string, {
            outcome: {
              message: 'Data was successfully submitted.',
            },
          });

          handleOpenToast({ message: 'Data uploaded successfully', severity: 'success' });

          await refetchSubmodules();
          await refetchSubmodule();
          setFlatFileData(records.data.records.map((record) => record.values));
        } catch (error) {
          handleOpenToast({ message: 'Failed to upload data', severity: 'error' });
          await api.jobs.fail(jobId as string, {
            outcome: {
              message: 'This job encountered an error.',
            },
          });
        }
      });
    });

    listener.on('file:created', async (event) => {
      try {
        const wSpaceId = event?.src?.context?.spaceId ?? 'unknown'; // Provide a default value
        // Await the API call to ensure it's properly handled
        await fetchEmailNotification(wSpaceId).unwrap();
        handleOpenToast({ message: 'Email sent successfully to analyst', severity: 'success' });
      } catch (error) {
        handleOpenToast({ message: 'Failed to send email', severity: 'error' });
      }
    });
  });

  if (spaceId === undefined) {
    return null;
  }

  const buttonPropsUpdated = isAdmin ? {} : buttonProps;

  const { sx = {}, ...props } = buttonPropsUpdated;

  return (
    <>
      <RedesignAppButton
        sx={{ alignSelf: 'end', ...sx }}
        appVariant="filled"
        onClick={() => {
          openPortal();
        }}
        {...props}
      >
        Upload File
      </RedesignAppButton>
      <Space id={spaceId} />
    </>
  );
};

export default FlatfilePortal;
