import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../utils/hooks/useAppSelector';
import { useGetBlobTokenQuery } from '../../../utils/redux/api';
import { selectCurrentKeyTheme } from '../../../utils/redux/keyThemeSlice';
import { type KeyThemeResource } from '../../../utils/types/KeyTheme';

const SupportingDataCarousel: React.FC = () => {
  const currentKeyTheme = useAppSelector((state) => selectCurrentKeyTheme(state));
  const { workspaceId } = useParams();
  const { data } = useGetBlobTokenQuery(Number(workspaceId));
  const { palette } = useTheme();

  if (currentKeyTheme === null) return;

  const kpis = currentKeyTheme?.kpis ?? [];
  const consumerSurveys = currentKeyTheme?.consumerSurveys ?? [];

  // Function to get image elements for both KPIs and Consumer Surveys
  const getImageElement = (item: any) => (
    <Box key={item.id} sx={{ height: '500px', width: '100%' }} display="flex" justifyContent="center">
      <img
        src={`${item.file_storage_path}?${data?.sas_token}`}
        alt="Supporting Data"
        style={{
          backgroundColor: palette.gray.white,
          border: `1px solid ${palette.gray[250]}`,
          objectFit: 'contain',
          width: '100%',
        }}
      />
    </Box>
  );

  // Merge KPIs and Consumer Surveys into a single array
  const allImages = [...kpis, ...consumerSurveys];

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant="headlineMedium">Supporting Data</Typography>
      <Carousel swipe={false} autoPlay={false} animation="slide" sx={{ width: '100%' }}>
        {allImages.map((item) => getImageElement(item))}
      </Carousel>
    </Box>
  );
};

export default SupportingDataCarousel;
