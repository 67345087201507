import React, { Fragment, useRef, useEffect, useContext } from 'react';
import classes from './ChatBox.module.css';
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Popper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import chatBot from '../../../../assets/svg/chatBot.svg';
import ChatMessage from './ChatMessage';
import {
  useSendMessageMutation,
  useUploadDataMutation,
  useGetUpdatedWorkspaceListQuery,
  useCheckWorkspaceChatDataMutation,
} from '../../../../utils/redux/endpoints/chatbotEndpoints';
import {
  type IDataUploadFormValues,
  type IBMChartRawData,
  type IChat,
  type IChatResponse,
  type IKPIData,
  type IChatbotUploadData,
  type ICheckWorkspaceChatDataResponse,
  type IChatFileValidation,
  type IBenchmarkClientData,
  type IChannelUsageData,
  type IChannelSatisfactionData,
  type INPSData,
  type IDNPSData,
} from '../../../../utils/types/ChatBot';
import ThumbsUpDownRoundedIcon from '@mui/icons-material/ThumbsUpDownRounded';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import HandshakeRoundedIcon from '@mui/icons-material/HandshakeRounded';
import EmojiEventsRoundedIcon from '@mui/icons-material/EmojiEventsRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import SettingsIcon from '@mui/icons-material/Settings';
import FileUpload from './FileUpload';
import AppCircularProgress from '../../../../components/materials/loading/AppCircularProgress';
import { useGetAllWorkspacesQuery, useGetUserQuery } from '../../../../utils/redux/api';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { useAppSelector } from '../../../../utils/hooks/useAppSelector';
import * as XLSX from 'xlsx';
import { ToastContext } from '../../../../context/toastContext';

interface IChatBoxProps {
  setIsChatDialogOpen: (value: boolean) => void;
  setIsChatBoxFullScreen: (value: boolean) => void;
  isChatBoxFullScreen: boolean;
}

const channelUsageQuestions: string[] = [
  '% of customers who use Mobile banking app more than once a week in Germany?',
  'What % of Low income Male customers using ATM globally are under 40?',
  'What are the top 3 most popular channels used by customers in Germany under the age of 35?',
  'Which bank has the highest proportion of customers using mobile app in Spain?',
  'Draw me a chart for users across different age groups in France',
  'Create a chart of branch usage in Germany',
];
const benchmarkingQuestions: string[] = [
  "How does my bank's customer acquisition rate compare to the industry average?",
  "Can you provide insights into my bank's cost to income ratio for 2023?",
  'How is my bank performing in terms of digital sales compared to branch sales?',
  'What is the attrition rate for affluent customers at my bank?',
  'How does the average balance per customer with a savings account compare to peers?',
  'Can you give me a breakdown of the personnel cost per FTE at my bank?',
  "What percentage of my bank's customers are active in digital channels?",
  "What are the key performance indicators for my bank's investment products?",
  'How is my bank performing on costs?',
];
const channelSatisfactionQuestions: string[] = [
  'What is the overall net satisfaction for each channel in 2024?',
  "Which channel has the highest net satisfaction in the 'High Income' category?",
  'What is the average net satisfaction across all income groups for Internet Banking in 2024?',
  "Which channels have a net satisfaction below 50% in the 'Low Income' category?",
  'What is the trend of net satisfaction for meeting a banker via video across different income groups?',
  'How does net satisfaction for ATMs compare across different income groups?',
  "What is the difference in net satisfaction for mobile apps between 'High Income' and 'Low Income' groups?",
  "Which channel has the lowest net satisfaction in the '18-24' age group?",
  "What is the total net satisfaction for all channels in the 'Medium Income' group?",
];
const NPSQuestions: string[] = [
  'What is the Net Promoter Score (NPS) for all banks for the latest year?',
  'What is the ranking of banks based on primary customers in the most recent year?',
  'Can you get the NPS score for banks serving high-income customers?',
  'What was the NPS score for all banks in 2022?',
  'How does my bank perform on NPS?',
  'How do primary customers rate my bank on NPS?',
  'How do high income customers rank by bank on NPS?',
];
const NPSDQuestions: string[] = [
  'What are the top positive NPS drivers for Chase bank in USA',
  'Create a chart of top negative NPS drivers in USA',
  "Among Millennials, what percentage of users consider 'Good mobile banking app' as an important driver for their reference bank?",
  'What percentage of users in USA trust their bank to keep their money safe?',
  'What percentage of high-income users in Spain prefer banks with ATMs in convenient locations?',
  'What percentage of users aged 45-54 consider transparency about fees and charges an important factor?',
];

const ChatBox: React.FC<IChatBoxProps> = ({ setIsChatDialogOpen, setIsChatBoxFullScreen, isChatBoxFullScreen }) => {
  const email = useAppSelector((state) => state?.auth?.userDetails?.email?.toLowerCase() ?? '');
  const [message, setMessage] = React.useState<string>('');
  const [chatMessages, setChatMessages] = React.useState<any[]>([]);
  const [channelUsageChatMessages, setChannelUsageChatMessages] = React.useState<any[]>([]);
  const [benchmarkingChatMessages, setBenchmarkingChatMessages] = React.useState<any[]>([]);
  const [satisfactionChatMessages, setSatisfactionChatMessages] = React.useState<any[]>([]);
  const [NPSChatMessages, setNPSChatMessages] = React.useState<any[]>([]);
  const [DNPSChatMessages, setDNPSChatMessages] = React.useState<any[]>([]);
  const chatEndRef = useRef<HTMLDivElement | null>(null);
  const [isChatLoading, setIsChatLoading] = React.useState<boolean>(false);
  const [sendMessage] = useSendMessageMutation(); // Removed unused vars isLoading and error
  const [uploadData] = useUploadDataMutation();

  const {
    data: clientData,
    isLoading: isAllWorkspacesLoading,
    isFetching: isAllWorkspacesFetching,
    isError,
  } = useGetAllWorkspacesQuery(null);

  const [selectedWorkspace, setSelectedWorkspace] = React.useState<string | null>(null);

  const [isWorkspaceDataAvailable] = useCheckWorkspaceChatDataMutation();

  let typingInterval: NodeJS.Timeout | null = null;
  const [chatTopic, setChatTopic] = React.useState<string | null>(null);
  const [isChatMode, setISChatMode] = React.useState<boolean>(false);
  const [chatOptions, setChatOptions] = React.useState<string[] | any>([]);
  const [selectedOptions, setSelectedOptions] = React.useState<Set<string>>(new Set());
  const [workspaceList, setWorkspaceList] = React.useState<string[]>([]);
  const [showAll, setShowAll] = React.useState<boolean>(false);
  const displayedOptions = showAll ? chatOptions : chatOptions.slice(0, 10);
  const [isShowConfiguration, setIsShowConfiguration] = React.useState<boolean>(false);
  const [isConfDialogOpen, setIsConfDialogOpen] = React.useState<boolean>(false);
  const [isChatNotAllowed, setIsChatNotAllowed] = React.useState<boolean>(true);
  const storedWorkspaceData = localStorage.getItem('userWorkspaces');
  const userWorkSpaces = storedWorkspaceData != null ? JSON.parse(storedWorkspaceData) : [];
  const [fileValidation, setFileValidation] = React.useState<IChatFileValidation | null>({
    isValid: false,
    errorMessage: '',
    type: 'Select all the required fields and upload the file to continue.',
  });
  const [isFileReading, setIsFileReading] = React.useState<boolean>(false);
  const { handleOpenToast } = useContext(ToastContext);
  const [formValues, setFormValues] = React.useState<IDataUploadFormValues>({
    dataType: null,
    clientName: '',
    surveyModuleName: '',
    file: null,
  });

  const [isDataUploading, setIsDataUploading] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { userDetails } = useAppSelector((state) => state.auth);
  const {
    data: updatedWorkspaceList,
    isLoading: isWorkSpaceListLoading,
    isFetching: isWorkSpaceListFetching,
  } = useGetUpdatedWorkspaceListQuery(null, {
    skip: userDetails.roles != null && !userDetails?.roles.includes('admin'),
  });
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (userDetails != null) {
      if (userDetails.roles != null && userDetails?.roles.includes('admin')) {
        setIsShowConfiguration(true);
      }
    }
    const tmpChannelChats = sessionStorage.getItem('channelUsageChatMessages');
    const tmpBenchmarkingChats = sessionStorage.getItem('benchmarkingChatMessages');
    const tmpSatisfactionChats = sessionStorage.getItem('satisfactionChatMessages');
    const tmpNPSChats = sessionStorage.getItem('NPSChatMessages');
    const tmpDNPSChats = sessionStorage.getItem('DNPSChatMessages');
    if (tmpChannelChats !== null) {
      setChannelUsageChatMessages(JSON.parse(tmpChannelChats) as any[]);
    }
    if (tmpBenchmarkingChats !== null) {
      setBenchmarkingChatMessages(JSON.parse(tmpBenchmarkingChats) as any[]);
    }
    if (tmpSatisfactionChats !== null) {
      setSatisfactionChatMessages(JSON.parse(tmpSatisfactionChats) as any[]);
    }
    if (tmpNPSChats !== null) {
      setNPSChatMessages(JSON.parse(tmpNPSChats) as any[]);
    }
    if (tmpDNPSChats !== null) {
      setDNPSChatMessages(JSON.parse(tmpDNPSChats) as any[]);
    }
  }, []);

  useEffect(() => {
    if (channelUsageChatMessages.length > 0) {
      sessionStorage.setItem('channelUsageChatMessages', JSON.stringify(channelUsageChatMessages));
    }
    if (benchmarkingChatMessages.length > 0) {
      sessionStorage.setItem('benchmarkingChatMessages', JSON.stringify(benchmarkingChatMessages));
    }
    if (satisfactionChatMessages.length > 0) {
      sessionStorage.setItem('satisfactionChatMessages', JSON.stringify(satisfactionChatMessages));
    }
    if (NPSChatMessages.length > 0) {
      sessionStorage.setItem('NPSChatMessages', JSON.stringify(NPSChatMessages));
    }
    if (DNPSChatMessages.length > 0) {
      sessionStorage.setItem('DNPSChatMessages', JSON.stringify(DNPSChatMessages));
    }
    scrollToBottom();
  }, [
    channelUsageChatMessages,
    benchmarkingChatMessages,
    satisfactionChatMessages,
    NPSChatMessages,
    DNPSChatMessages,
  ]);

  useEffect(() => {
    setChatMessages([]);
    if (chatTopic === '') {
      if (!isShowConfiguration && userWorkSpaces != null && userWorkSpaces?.length > 0) {
        //  change here
        const workSpaceInfo = userWorkSpaces[userWorkSpaces.length - 1];
        const activeWorkspaceForClient: string | undefined = workSpaceInfo.workspace_name;
        if (activeWorkspaceForClient != null && userDetails.roles != null && !userDetails.roles.includes('admin')) {
          // change here
          setSelectedWorkspace(activeWorkspaceForClient);
        }
      }
      setChatMessages([...benchmarkingChatMessages]);
    } else if (chatTopic === '1') {
      setChatMessages([...channelUsageChatMessages]);
    }
  }, [chatTopic]);

  useEffect(() => {
    if (clientData !== undefined && clientData?.length > 0) {
      setWorkspaceList(
        clientData.map((workspace) => {
          return workspace.workspace_name;
        }),
      );
    }
  }, [clientData]);

  useEffect(() => {
    const checkWorkspaceChatData = async () => {
      if (selectedWorkspace != null && selectedWorkspace !== '' && chatTopic === '') {
        try {
          const isChatAllowed = await handleWorkspaceDataCheck(selectedWorkspace);
          setIsChatNotAllowed(!isChatAllowed);
          if (!isChatAllowed && isShowConfiguration) {
            setChatMessages([
              {
                id: 'genie',
                message:
                  'Sorry, this workspace does not have benchmark data available as of now, please upload the data for this workspace first.',
                is_chart: false,
              },
            ]);
          } else if (!isChatAllowed && !isShowConfiguration) {
            setChatMessages([
              {
                id: 'genie',
                message:
                  'Sorry, this workspace does not have benchmark data available as of now, please get in touch with BCG REBEX team for more information.',
                is_chart: false,
              },
            ]);
          }
        } catch (error) {
          console.error('Error checking workspace chat data:', error);
        }
      }
    };
    void checkWorkspaceChatData();
    setChatMessages([]);
  }, [selectedWorkspace]);

  const handleWorkspaceDataCheck = async (activeWorkspaceForClient: string) => {
    setIsDataUploading(true);
    const response: ICheckWorkspaceChatDataResponse = await isWorkspaceDataAvailable({
      workspaceName: activeWorkspaceForClient,
    }).unwrap();
    setIsDataUploading(false);
    return response.is_benchmark_data;
  };

  const toggleShowAll = () => {
    setShowAll((prev) => !prev);
  };

  const updateChatChannel = (chats: any) => {
    if (chatTopic === '1') {
      setChannelUsageChatMessages((prev) => [...prev, chats]);
    } else if (chatTopic === '') {
      setBenchmarkingChatMessages((prev) => {
        console.log(chats);
        return [...prev, chats];
      });
    } else if (chatTopic === '2') {
      setSatisfactionChatMessages((prev) => [...prev, chats]);
    } else if (chatTopic === '3') {
      setNPSChatMessages((prev) => [...prev, chats]);
    } else if (chatTopic === '4') {
      setDNPSChatMessages((prev) => [...prev, chats]);
    }
  };

  const handleOptionSelection = (option: string) => {
    setSelectedOptions((prev: Set<string>) => {
      const newSet = new Set(prev);
      if (newSet.has(option)) {
        newSet.delete(option); // Remove the option if it already exists
      } else {
        newSet.add(option); // Add the option if it doesn't exist
      }
      setMessage(Array.from(newSet).join(', '));
      return newSet;
    });
  };

  const sendMessageHandler = async (event: React.FormEvent) => {
    event.preventDefault();
    if (message.trim() === '') return;

    // Add user's message
    setChatMessages([...chatMessages, { id: 'user', message }]);
    updateChatChannel({ id: 'user', message });
    setMessage('');
    setIsChatLoading(true);

    // Add "genie" placeholder message
    setChatMessages((prevMessages) => [...prevMessages, { id: 'genie', message: 'Evaluating your query' }]);

    // Start cycling text messages
    startGenieTyping();

    // Perform the API call
    if (chatOptions.length === 0) {
      await postNewMessageRequest(message);
    } else if (chatOptions.length > 0) {
      const optionList = message.split(',').map((item) => item.trim());
      setChatOptions([]);
      await postNewMessageRequest(optionList);
      setSelectedOptions(new Set());
    }
  };

  const postNewMessageRequest = async (message: string | string[]) => {
    try {
      const payload = { message, topic: chatTopic, work_space: chatTopic === '' ? selectedWorkspace : '' };
      const response: IChatResponse = await sendMessage(payload).unwrap();

      // Stop typing effect when response is received
      stopGenieTyping();
      let newChat: IChat;
      if (response?.chart?.is_chart === true) {
        const { categories, values } = response.chart.data;
        const chartMessage = response.chart?.message !== undefined ? response.chart?.message : 'Here is your chart';
        newChat = {
          id: 'genie',
          message: response.message ?? '',
          is_chart: true,
          chart: { categories, values, chartMessage },
        };
        setChatMessages((prevMessages) => [...prevMessages.slice(0, -1), newChat]);
      } else {
        newChat = {
          id: 'genie',
          message: response.message ?? '',
          is_chart: false,
        };

        if (Object.keys(response).includes('data') && response.data !== undefined && response.data?.length > 0) {
          const rawKpiData: IKPIData[] = response.data;
          const headers: string[] = generateHeaders(rawKpiData);
          const chartData: IBMChartRawData[] = generateDataForAllKPIs(rawKpiData);
          console.log(headers);
          console.log(chartData);
          newChat.bmChartData = { headers, data: chartData };
        }
        setChatMessages((prevMessages) => [...prevMessages.slice(0, -1), newChat]);
        if (response.kpi_list !== undefined) {
          setChatOptions(response.kpi_list);
        }
      }
      updateChatChannel(newChat);
      setIsChatLoading(false);
    } catch (err) {
      console.error('Failed to send message:', err);
      const newChat: IChat = { id: 'genie', message: 'Something went wrong. Please try again.', is_chart: false };
      // Stop typing effect if there’s an error
      stopGenieTyping();
      setChatMessages((prevMessages) => [...prevMessages.slice(0, -1), newChat]);
      updateChatChannel(newChat);
      setIsChatLoading(false);
    }
  };

  const startGenieTyping = () => {
    const typingMessages = [
      'Evaluating your query',
      'Our data ninjas are wrangling colossal bytes 🥷💾 — almost there!',
      'Analyzing the data 📊🔍',
    ];
    let currentIndex = 0;

    // Start cycling through messages
    typingInterval = setInterval(() => {
      setChatMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        updatedMessages[updatedMessages.length - 1] = {
          ...updatedMessages[updatedMessages.length - 1],
          message: typingMessages[currentIndex],
        };
        return updatedMessages;
      });

      // Cycle through the messages
      currentIndex = (currentIndex + 1) % typingMessages.length;
    }, 10000); // Change message every 2 seconds
  };

  const stopGenieTyping = () => {
    if (typingInterval !== null) {
      clearInterval(typingInterval);
      typingInterval = null;
    }
  };

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const chatToggleHandler = (value: boolean) => {
    setISChatMode(value);
    setMessage('');
    setChatOptions([]);
    setSelectedWorkspace(null);
    if (value) {
      setChatTopic('');
    } else {
      setChatTopic(null);
    }
  };

  const generateHeaders = (data: IKPIData[]): string[] => {
    // Extract unique peer group names
    const peerGroups = Array.from(new Set(data.map((item) => item.peer_group_name)));
    const clientName: string = data.length > 0 ? data[0].client_name : 'Client';

    // Generate headers
    const headers: string[] = [];
    peerGroups.forEach((peerGroup) => {
      headers.push(`${peerGroup} Best Quartile`, `${peerGroup} Average`);
    });

    // Add client name as the last header
    headers.push(clientName);

    return headers;
  };

  const generateDataForAllKPIs = (data: IKPIData[]): IBMChartRawData[] => {
    // Extract unique KPI names
    const kpiNames = Array.from(new Set(data.map((item) => item.kpi_name)));

    return kpiNames.map((kpiName) => {
      // Filter data for the current KPI
      const kpiData = data.filter((item) => item.kpi_name === kpiName);

      // Extract unique peer group names
      const peerGroups = Array.from(new Set(kpiData.map((item) => item.peer_group_name)));

      // Get the unit for the KPI (assuming it's the same across entries for the same KPI)
      const kpiUnit = kpiData.length > 0 ? kpiData[0].unit : '';

      // Generate chart data in the required order
      const chartData: number[] = [];
      peerGroups.forEach((peerGroup) => {
        const groupData: IKPIData | undefined = kpiData.find((item) => item.peer_group_name === peerGroup);

        if (groupData !== undefined && groupData !== null) {
          const bestQuartile =
            groupData.best_quartile !== null && groupData.best_quartile !== undefined
              ? Number(groupData.best_quartile)
              : 0;
          const average =
            groupData.average !== null && groupData.average !== undefined ? Number(groupData.average) : 0;

          chartData.push(
            !isNaN(bestQuartile) ? bestQuartile : 0, // Safely convert to number or default to 0
            !isNaN(average) ? average : 0, // Safely convert to number or default to 0
          );
        } else {
          chartData.push(0, 0); // Default values if data is missing
        }
      });

      // Add client value
      const clientValue =
        kpiData.length > 0 && kpiData[0].client_value !== null && kpiData[0].client_value !== undefined
          ? Number(kpiData[0].client_value)
          : 0;

      chartData.push(!isNaN(clientValue) ? clientValue : 0); // Safely convert to number or default to 0

      return {
        kpiName: kpiName,
        chartData: chartData,
        kpiUnit: kpiUnit, // Include the unit in the output
      };
    });
  };

  const handleFormChange = (key: string, value: string | null) => {
    setFormValues((prev: IDataUploadFormValues) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleFileChange = (file: File) => {
    setIsFileReading(true);
    const uploadedFileName = file.name;
    // reading the client name from the file for validation
    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      setIsFileReading(false);
      if (typeof e.target?.result === 'undefined' || e.target?.result === null) return;
      const data = new Uint8Array(e.target.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0]; // Get first sheet name
      const sheet = workbook.Sheets[sheetName];
      if (workbook.SheetNames.length > 1) {
        setFileValidation({
          isValid: false,
          errorMessage: 'Only one sheet is allowed in the file, please rectify the file and try again.',
          type: 'error',
        });
      } else if (formValues.dataType != null && formValues.dataType === 'BMD') {
        const jsonData: IBenchmarkClientData[] = XLSX.utils.sheet_to_json(sheet); // Convert to JSON
        checkBenchmarkFileisValid(jsonData, uploadedFileName);
      } else if (
        formValues.dataType != null &&
        formValues.dataType === 'CSD' &&
        formValues.surveyModuleName === 'CU'
      ) {
        const jsonData: IChannelUsageData[] = XLSX.utils.sheet_to_json(sheet); // Convert to JSON
        checkChannelUsageFileisValid(jsonData, uploadedFileName);
      } else if (
        formValues.dataType != null &&
        formValues.dataType === 'CSD' &&
        formValues.surveyModuleName === 'CS'
      ) {
        const jsonData: IChannelSatisfactionData[] = XLSX.utils.sheet_to_json(sheet); // Convert to JSON
        checkChannelSatisfactionFileisValid(jsonData, uploadedFileName);
      } else if (
        formValues.dataType != null &&
        formValues.dataType === 'CSD' &&
        formValues.surveyModuleName === 'NPS'
      ) {
        const jsonData: INPSData[] = XLSX.utils.sheet_to_json(sheet); // Convert to JSON
        checkNPSFileisValid(jsonData, uploadedFileName);
      } else if (
        formValues.dataType != null &&
        formValues.dataType === 'CSD' &&
        formValues.surveyModuleName === 'DNPS'
      ) {
        const jsonData: IDNPSData[] = XLSX.utils.sheet_to_json(sheet); // Convert to JSON
        checkDNPSFileisValid(jsonData, uploadedFileName);
      }
    };
    reader.readAsArrayBuffer(file);
    setFormValues((prev: IDataUploadFormValues) => {
      return {
        ...prev,
        file: file,
      };
    });
  };

  const checkBenchmarkFileisValid = (fileData: IBenchmarkClientData[], uploadedFileName: string) => {
    const clientNames = Array.from(
      new Set(fileData.map((data: IBenchmarkClientData) => data.CLIENT_NAME ?? '')).values(),
    );
    const firstRowData = fileData[0];
    const columnNames: string[] = Object.keys(firstRowData as object);
    const requiredBenchmarkColumns = new Set([
      'AVERAGE',
      'BEST_QUARTILE',
      'CLIENT_VALUE',
      'DENOMINATOR',
      'DENOMINATOR_VALUE',
      'KPI_CATEGORY',
      'NUMERATOR',
      'NUMERATOR_VALUE',
      'CLIENT_NAME',
      'KPI_NAME',
      'PEER_GROUP_NAME',
      'UNIT',
    ]);
    for (let columnIndex = 0; columnIndex < columnNames.length; columnIndex++) {
      const columnName = columnNames[columnIndex];
      if (!requiredBenchmarkColumns.has(columnName.toUpperCase())) {
        setFileValidation({
          isValid: false,
          errorMessage: 'Invalid file format, check all the columns and try again.',
          type: 'error',
        });
        return;
      }
    }
    const fileName = uploadedFileName.split('.')[0];

    if (clientNames.length === 0) {
      setFileValidation({ isValid: false, errorMessage: 'Client name is not available in the file', type: 'error' });
    } else if (clientNames.length > 1) {
      setFileValidation({
        isValid: false,
        errorMessage: 'Multiple client names are available in the file, please upload a correct file',
        type: 'error',
      });
    } else if (fileName !== formValues.clientName) {
      setFileValidation({
        isValid: false,
        errorMessage: 'Filename must be same as selected workspace name',
        type: 'error',
      });
    } else if (fileName !== clientNames[0]) {
      setFileValidation({
        isValid: true,
        errorMessage: 'Client name in the file does not match with the selected workspace name',
        type: 'warning',
      });
    } else if (columnNames.length !== requiredBenchmarkColumns.size) {
      setFileValidation({
        isValid: false,
        errorMessage: `Invalid file, missing columns: (${Array.from(requiredBenchmarkColumns.keys())
          .filter((column: string) => !columnNames.includes(column))
          .join(', ')})`,
        type: 'error',
      });
    } else {
      setFileValidation({ isValid: true, errorMessage: '', type: 'success' });
    }
  };

  const checkChannelUsageFileisValid = (fileData: IChannelUsageData[], uploadedFileName: string) => {
    const columnNames: string[] = Object.keys(fileData[0] as object);
    const requiredChannelUsageColumns = new Set([
      'USER_ID',
      'AGE',
      'AGE_GROUP',
      'GENDER',
      'HIGHEST_EDUCATION',
      'PRIMARY_BANK',
      'COUNTRY',
      'CHANNEL',
      'INC_SEGMENT',
      'MARITAL_STATUS',
      'FREQUENCY_OF_CHANNEL_USE',
      'YEAR',
      'ENGAGEMENT_LEVEL',
    ]);
    const regexToCheckFileName = /^CSCU_(20[2-9][0-9]|2[1-9][0-9]{2})$/;
    const fileName = uploadedFileName.split('.')[0];

    for (let columnIndex = 0; columnIndex < columnNames.length; columnIndex++) {
      if (!requiredChannelUsageColumns.has(columnNames[columnIndex])) {
        setFileValidation({
          isValid: false,
          errorMessage: 'Invalid file format, Wrong column name: ' + columnNames[columnIndex],
          type: 'error',
        });
        return;
      }
    }
    if (!regexToCheckFileName.test(fileName)) {
      setFileValidation({
        isValid: false,
        errorMessage: 'Invalid file name, Filename should follow CSCU_YYYY format',
        type: 'error',
      });
    } else if (columnNames.length !== requiredChannelUsageColumns.size) {
      setFileValidation({
        isValid: false,
        errorMessage: `Invalid file, missing columns: (${Array.from(requiredChannelUsageColumns.keys())
          .filter((column: string) => !columnNames.includes(column))
          .join(', ')})`,
        type: 'error',
      });
    } else {
      setFileValidation({ isValid: true, errorMessage: '', type: 'success' });
    }
  };

  const checkChannelSatisfactionFileisValid = (fileData: IChannelSatisfactionData[], uploadedFileName: string) => {
    const columnNames: string[] = Object.keys(fileData[0] as object);
    const requiredColumns = new Set(['CHANNEL', 'CUTS', 'YEAR', 'NET_SATISFACTION']);
    const regexToCheckFileName = /^CSCS_(20[2-9][0-9]|2[1-9][0-9]{2})$/;
    const fileName = uploadedFileName.split('.')[0];

    for (let columnIndex = 0; columnIndex < columnNames.length; columnIndex++) {
      if (!requiredColumns.has(columnNames[columnIndex])) {
        setFileValidation({
          isValid: false,
          errorMessage: 'Invalid file, unknown column: ' + columnNames[columnIndex],
          type: 'error',
        });
        return;
      }
    }
    if (!regexToCheckFileName.test(fileName)) {
      setFileValidation({
        isValid: false,
        errorMessage: 'Invalid file name, Filename should follow CSCS_YYYY format',
        type: 'error',
      });
    } else if (columnNames.length !== requiredColumns.size) {
      setFileValidation({
        isValid: false,
        errorMessage: `Invalid file, missing columns: (${Array.from(requiredColumns.keys())
          .filter((column: string) => !columnNames.includes(column))
          .join(', ')})`,
        type: 'error',
      });
    } else {
      setFileValidation({ isValid: true, errorMessage: '', type: 'success' });
    }
  };

  const checkNPSFileisValid = (fileData: INPSData[], uploadedFileName: string) => {
    const columnNames: string[] = Object.keys(fileData[0] as object);
    const requiredColumns = new Set([
      'BANK',
      'YEAR',
      'SEGMENT',
      'SAMPLE_SIZE',
      'SCORE',
      'COUNTRY_AVERAGE',
      'RANK',
      'TOP_QUARTILE',
    ]);
    const regexToCheckFileName = /^CSNPS_(20[2-9][0-9]|2[1-9][0-9]{2})$/;
    const fileName = uploadedFileName.split('.')[0];

    for (let columnIndex = 0; columnIndex < columnNames.length; columnIndex++) {
      if (!requiredColumns.has(columnNames[columnIndex])) {
        setFileValidation({
          isValid: false,
          errorMessage: 'Invalid file, unknown column: ' + columnNames[columnIndex],
          type: 'error',
        });
        return;
      }
    }
    if (!regexToCheckFileName.test(fileName)) {
      setFileValidation({
        isValid: false,
        errorMessage: 'Invalid file name, Filename should follow CSNPS_YYYY format',
        type: 'error',
      });
    } else if (columnNames.length !== requiredColumns.size) {
      setFileValidation({
        isValid: false,
        errorMessage: `Invalid file, missing columns: (${Array.from(requiredColumns.keys())
          .filter((column: string) => !columnNames.includes(column))
          .join(', ')})`,
        type: 'error',
      });
    } else {
      setFileValidation({ isValid: true, errorMessage: '', type: 'success' });
    }
  };

  const checkDNPSFileisValid = (fileData: IDNPSData[], uploadedFileName: string) => {
    const columnNames: string[] = Object.keys(fileData[0] as object);
    const requiredColumns = new Set([
      'USER_ID',
      'BANK_CATEGORY',
      'GENDER',
      'COUNTRY',
      'AGE',
      'AGE_SEGMENT',
      'DRIVER_NAME',
      'OPTION_LABEL',
      'REFERENCE_BANK',
      'YEAR',
      'DRIVER_TYPE',
      'INCOME_SEGMENT',
    ]);
    const regexToCheckFileName = /^CSDNPS_(20[2-9][0-9]|2[1-9][0-9]{2})$/;
    const fileName = uploadedFileName.split('.')[0];

    for (let columnIndex = 0; columnIndex < columnNames.length; columnIndex++) {
      if (!requiredColumns.has(columnNames[columnIndex])) {
        setFileValidation({
          isValid: false,
          errorMessage: 'Invalid file, unknown column: ' + columnNames[columnIndex],
          type: 'error',
        });
        return;
      }
    }
    if (!regexToCheckFileName.test(fileName)) {
      setFileValidation({
        isValid: false,
        errorMessage: 'Invalid file name, Filename should follow CSDNPS_YYYY format',
        type: 'error',
      });
    } else if (columnNames.length !== requiredColumns.size) {
      setFileValidation({
        isValid: false,
        errorMessage: `Invalid file, missing columns: (${Array.from(requiredColumns.keys())
          .filter((column: string) => !columnNames.includes(column))
          .join(', ')})`,
        type: 'error',
      });
    } else {
      setFileValidation({ isValid: true, errorMessage: '', type: 'success' });
    }
  };

  const handleUploadData = async () => {
    if (clientData !== undefined && clientData.length > 0) {
      setIsConfDialogOpen(true);
    }
  };

  const saveUploadedData = async () => {
    if (fileValidation?.isValid === false) {
      handleOpenToast({
        severity: 'error',
        message: fileValidation.errorMessage === '' ? fileValidation.type : fileValidation.errorMessage,
      });
      return;
    }
    try {
      if (formValues.dataType !== null && formValues.clientName !== null && formValues.file !== null) {
        const payload: IChatbotUploadData = {
          fileData: formValues.file,
          isClientData: (formValues.clientName !== null).toString(),
          clientName: formValues.clientName ?? '',
          surveyModuleName: formValues.surveyModuleName ?? '',
        };
        setIsDataUploading(true);
        const response = await uploadData(payload).unwrap();
        handleOpenToast({
          severity: 'success',
          message: response.message,
        });
        setIsDataUploading(false);
        setIsConfDialogOpen(false);
        setFormValues({
          dataType: null,
          clientName: '',
          surveyModuleName: '',
          file: null,
        });
      } else {
        console.error('Missing required form values. Cannot upload data.');
      }
    } catch (error) {
      setIsDataUploading(false);
      console.error('Error uploading data:', error);
    }
  };

  const handleWorkspaceIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl !== null ? null : event.currentTarget);
  };

  const handleWorkspaceClose = () => {
    setAnchorEl(null);
  };

  const showChatMessages = () => {
    if (chatTopic === '' && selectedWorkspace !== null && chatMessages.length > 0) {
      return true;
    } else if (chatTopic !== null && ['1', '2', '3', '4', '5'].includes(chatTopic) && chatMessages.length > 0) {
      return true;
    } else return false;
  };

  return (
    <Fragment>
      <DialogTitle
        className={classes.bot_title_wraper}
        sx={{
          color: '#fff',
          padding: '0.7rem 1rem !important',
          display: 'flex',
          gap: '.3rem',
          justifyContent: 'space-between',
          alignItems: 'center',
          boxShadow: '5px 5px 1px #000 !important',
          transition: '2s ease-in-out',
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography className={classes.bot_title}>
            GENIE <span className={classes.title_subheader}>by rebex</span>{' '}
          </Typography>
        </Box>

        <Box className={classes.switch_wrapper}>
          <Typography variant="caption" sx={{ fontSize: '0.8em' }}>
            Consumer Survey Queries
          </Typography>
          <FormControlLabel
            control={
              <Switch
                color="default"
                sx={{ m: 1 }}
                checked={isChatMode && chatTopic === ''}
                onChange={(event) => {
                  chatToggleHandler(event.target.checked);
                }}
              />
            }
            label="Benchmark Result Queries"
          />
        </Box>

        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <Tooltip title="Go to landing page" placement="top">
            <IconButton
              onClick={() => {
                chatToggleHandler(false);
              }}
              sx={{ color: '#fff' }}
            >
              <HomeRoundedIcon />
            </IconButton>
          </Tooltip>
          {workspaceList !== undefined &&
          workspaceList.length > 0 &&
          isShowConfiguration &&
          isChatMode &&
          chatTopic === '' ? (
            <>
              <Tooltip title="Select Workspace" placement="top">
                <IconButton onClick={handleWorkspaceIconClick} sx={{ color: '#fff' }}>
                  <WorkspacesIcon />
                </IconButton>
              </Tooltip>
              <Popper open={open} anchorEl={anchorEl} placement="bottom-start" style={{ zIndex: 1300 }}>
                <ClickAwayListener onClickAway={handleWorkspaceClose}>
                  <Paper sx={{ width: 300, padding: 2 }}>
                    <Autocomplete
                      size="small"
                      options={updatedWorkspaceList?.workspaces ?? []}
                      value={selectedWorkspace}
                      onChange={(event: any, newValue: string | null) => {
                        setSelectedWorkspace(newValue);
                        setChatMessages([]);
                      }}
                      renderInput={(params) => <TextField {...params} label="Search" variant="outlined" />}
                      fullWidth
                    />
                  </Paper>
                </ClickAwayListener>
              </Popper>
            </>
          ) : (
            <></>
          )}
          <Tooltip title="Configure Datasets" placement="top">
            <IconButton
              onClick={() => {
                void handleUploadData();
              }}
              sx={{ color: '#fff' }}
            >
              {isShowConfiguration ? <SettingsIcon /> : <></>}
            </IconButton>
          </Tooltip>
          <Tooltip title="Full Screen Toggle" placement="top">
            <IconButton
              onClick={() => {
                setIsChatBoxFullScreen(!isChatBoxFullScreen);
              }}
              sx={{ color: '#fff' }}
            >
              {isChatBoxFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip title="Close" placement="top">
            <IconButton
              onClick={() => {
                setIsChatDialogOpen(false);
              }}
              sx={{ color: '#fff' }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </DialogTitle>
      {isChatMode ? (
        <DialogContent className={classes.content_wrapper}>
          {showChatMessages() ? (
            <div className={classes.chat_wrapper}>
              {chatMessages.map((chat, index) => (
                <ChatMessage
                  key={index} // Added key prop
                  id={chat.id}
                  message={chat.message}
                  isLastIndex={index === chatMessages.length - 1}
                  isChatLoading={index === chatMessages.length - 1 ? isChatLoading : false}
                  isChart={chat.is_chart} // Use is_chart, not isChart
                  chart={
                    chat.chart !== undefined
                      ? {
                          categories: chat.chart.categories,
                          values: chat.chart.values,
                          chartMessage: chat.chart.chartMessage,
                        }
                      : undefined
                  }
                  mode={chatTopic === '' ? 'bm' : 'srv'}
                  bmChartData={chat.bmChartData} // Added mode prop, bm stands for benchmarking and srv stands for survey
                />
              ))}

              <div ref={chatEndRef} />
            </div>
          ) : (
            <section className={classes.no_chat_data}>
              <img src={chatBot} alt="chat icon" height="60px" width="60px" />
              <Typography className={classes.heading_font_style}>
                Hi, I am Genie. I can help you with{' '}
                {chatTopic !== '' ? 'Consumer Survey insights' : 'Benchmarked KPI comparison with Peers & Insights'}
              </Typography>
              <ul className={classes.question_card}>
                <Typography sx={{ textAlign: 'center', marginBottom: '1rem', marginLeft: '-1rem' }}>
                  {((chatTopic === '' && selectedWorkspace !== null) || chatTopic !== '') && isShowConfiguration
                    ? 'Ask me Anything like:'
                    : 'Select Workspace'}
                </Typography>
                {chatTopic === '1' &&
                  channelUsageQuestions.map((question, index) => (
                    <li
                      key={index} // Added key prop
                      className={classes.list_item}
                      onClick={() => {
                        setMessage(question);
                      }}
                    >
                      {question}
                    </li>
                  ))}
                {chatTopic === '2' &&
                  channelSatisfactionQuestions.map((question, index) => (
                    <li
                      key={index} // Added key prop
                      className={classes.list_item}
                      onClick={() => {
                        setMessage(question);
                      }}
                    >
                      {question}
                    </li>
                  ))}
                {chatTopic === '3' &&
                  NPSQuestions.map((question, index) => (
                    <li
                      key={index} // Added key prop
                      className={classes.list_item}
                      onClick={() => {
                        setMessage(question);
                      }}
                    >
                      {question}
                    </li>
                  ))}
                {chatTopic === '4' &&
                  NPSDQuestions.map((question, index) => (
                    <li
                      key={index} // Added key prop
                      className={classes.list_item}
                      onClick={() => {
                        setMessage(question);
                      }}
                    >
                      {question}
                    </li>
                  ))}

                {chatTopic === '' &&
                  selectedWorkspace !== null &&
                  selectedWorkspace !== null &&
                  benchmarkingQuestions.map((question, index) => (
                    <li
                      key={index} // Added key prop
                      className={classes.list_item}
                      onClick={() => {
                        setMessage(question);
                      }}
                    >
                      {question}
                    </li>
                  ))}
                {chatTopic === '' && selectedWorkspace === null && isShowConfiguration && (
                  <Box sx={{ marginLeft: '-1rem' }}>
                    <Autocomplete
                      size="small"
                      options={updatedWorkspaceList?.workspaces ?? []}
                      value={selectedWorkspace}
                      onChange={(event: any, newValue: string | null) => {
                        setSelectedWorkspace(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search Workspace"
                          variant="outlined"
                          sx={{
                            backgroundColor: 'rgba(255, 255, 255, 0.15)', // Slightly transparent white background
                            '& .MuiOutlinedInput-root': {
                              color: '#fff', // White text inside the input
                              borderColor: '#fff', // Ensure border is white always
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#fff', // White border always
                              },
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#fff', // White border on hover
                              },
                              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#fff', // White border on focus
                              },
                            },
                            '& .MuiInputLabel-root': {
                              color: 'rgba(255, 255, 255, 0.85)', // Almost white label when not focused
                              '&.Mui-focused': {
                                color: '#fff', // Fully white label when focused
                              },
                            },
                            '& .MuiSvgIcon-root': {
                              color: '#fff', // White dropdown arrow (icon)
                            },
                          }}
                        />
                      )}
                      fullWidth
                    />
                  </Box>
                )}
              </ul>
            </section>
          )}
        </DialogContent>
      ) : (
        <DialogContent sx={{ padding: 0, display: 'flex', gap: '1rem' }}>
          {!isChatMode && chatTopic === null ? (
            <Box className={classes.topic_nav_wrapper}>
              <Box
                className={classes.topic_nav}
                onClick={() => {
                  setChatTopic('1');
                  setISChatMode(true);
                }}
              >
                <section className={classes.nav_icon}>
                  <BarChartRoundedIcon sx={{ fontSize: '5em' }} />
                </section>
                <section className={classes.nav_title}>Channel Usage</section>
              </Box>
              <Box
                className={classes.topic_nav}
                onClick={() => {
                  setChatTopic('2');
                  setISChatMode(true);
                }}
              >
                <section className={classes.nav_icon}>
                  <ThumbsUpDownRoundedIcon sx={{ fontSize: '5em' }} />
                </section>
                <section className={classes.nav_title}>Channel Satisfaction</section>
              </Box>
              <Box
                className={classes.topic_nav}
                onClick={() => {
                  setChatTopic('3');
                  setISChatMode(true);
                }}
              >
                <section className={classes.nav_icon}>
                  <EmojiEventsRoundedIcon sx={{ fontSize: '5em' }} />
                </section>
                <section className={classes.nav_title}>Net Promoter Score</section>
              </Box>
              <Box
                className={classes.topic_nav}
                onClick={() => {
                  setChatTopic('4');
                  setISChatMode(true);
                }}
              >
                <section className={classes.nav_icon}>
                  <HandshakeRoundedIcon sx={{ fontSize: '5em' }} />
                </section>
                <section className={classes.nav_title}>Advocacy Drivers</section>
              </Box>
              {/* <Box className={classes.topic_nav_disabled}>
                <section className={classes.nav_icon}>
                  <GroupRoundedIcon sx={{ fontSize: '5em' }} />
                </section>
                <section className={classes.nav_title}>Product ownership</section>
              </Box> */}
            </Box>
          ) : (
            <></>
          )}
        </DialogContent>
      )}

      {isChatMode ? (
        <DialogActions className={classes.action_wrapper}>
          {(chatTopic === '' && selectedWorkspace !== null) || (chatTopic !== '' && isChatMode) ? (
            <form className={classes.form_wrapper} onSubmit={sendMessageHandler}>
              <div className={classes.option_wrapper}>
                {displayedOptions.map((kpi: string, i: number) => (
                  <Chip
                    key={i}
                    sx={{
                      backgroundColor: selectedOptions.has(kpi) ? '#00796b' : '#FFF',
                      color: selectedOptions.has(kpi) ? '#FFF' : '#00796b',
                      '&:hover': {
                        color: '#FFF',
                      },
                    }}
                    label={kpi}
                    variant="outlined"
                    onClick={() => {
                      handleOptionSelection(kpi);
                    }}
                  />
                ))}
                {chatOptions.length > 10 && (
                  <Typography className={classes.expand_options} onClick={toggleShowAll}>
                    {showAll ? 'Show Less' : 'Show More...'}
                  </Typography>
                )}
              </div>
              <div className={classes.input_message_wrapper}>
                <TextField
                  variant="filled"
                  value={message}
                  disabled={isChatLoading || chatOptions.length > 0 || (isChatNotAllowed && chatTopic === '')}
                  fullWidth
                  size="medium"
                  focused
                  placeholder="Ask me anything"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setMessage(event.target.value);
                  }}
                  sx={{
                    '& .MuiFilledInput-root': {
                      '&.Mui-focused': {
                        borderColor: '#fff',
                        backgroundColor: 'rgba(255, 255, 255, 0.15)',
                        '& .MuiFilledInput-notchedFilled': {
                          borderColor: '#fff',
                        },
                        '& input': {
                          color: '#fff',
                        },
                      },
                    },
                  }}
                />
              </div>

              {chatOptions.length > 0 && (
                <Tooltip title="Cancel Request" placement="top">
                  <IconButton
                    size="small"
                    color="error"
                    onClick={() => {
                      setChatOptions([]);
                      setSelectedOptions(new Set());
                      setIsChatLoading(false);
                      setMessage('');
                    }}
                    sx={{
                      backgroundColor: (theme) => theme.palette.error.main, // Use the error color from the theme
                      color: '#fff', // Set the icon color to white for contrast
                      '&:hover': {
                        backgroundColor: (theme) => theme.palette.error.dark, // Darker shade on hover
                      },
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              )}

              {message.trim() !== '' && (
                <div className={classes.chat_action_wrapper}>
                  <IconButton
                    sx={{ color: '#FFF' }}
                    disabled={message.trim() === ''}
                    type="submit"
                    aria-label="Send Message"
                  >
                    <SendIcon />
                  </IconButton>
                </div>
              )}
            </form>
          ) : (
            <></>
          )}
        </DialogActions>
      ) : (
        <></>
        // <DialogActions sx={{ height: '3.5rem' }} className={classes.action_wrapper}></DialogActions>
      )}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={isConfDialogOpen}
        onClose={() => {
          setIsConfDialogOpen(false);
          setFileValidation(null);
          setFormValues({
            dataType: null,
            clientName: '',
            surveyModuleName: '',
            file: null,
          });
        }}
      >
        <DialogTitle>Upload Data</DialogTitle>
        <DialogContent sx={{ minHeight: '5rem' }}>
          <FormControl fullWidth size="small" sx={{ marginTop: '0.5rem', marginBottom: '0.8rem' }} required>
            <InputLabel id="demo-simple-select-label">Select Data Type</InputLabel>
            <Select
              size="small"
              disabled={isFileReading || isDataUploading}
              required
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formValues.dataType}
              onChange={(event) => {
                handleFormChange('dataType', event.target.value?.toString() ?? '');
              }}
              label="Select Data Type"
            >
              <MenuItem value={'BMD'}>Benchmark Data</MenuItem>
              <MenuItem value={'CSD'}>Consumer Survey Data</MenuItem>
            </Select>
          </FormControl>
          {formValues.dataType === 'BMD' && (
            <FormControl fullWidth size="small" sx={{ marginTop: '0.5rem', marginBottom: '0.8rem' }}>
              <Autocomplete
                size="small"
                disabled={isFileReading || isDataUploading}
                value={formValues.clientName}
                disablePortal={false}
                onChange={(event, value: string | null) => {
                  handleFormChange('clientName', value);
                }}
                options={workspaceList ?? []}
                fullWidth
                renderInput={(params) => <TextField {...params} label="Select Workspace" required />}
              />
            </FormControl>
          )}
          {formValues.dataType === 'CSD' && (
            <FormControl fullWidth size="small" sx={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} required>
              <InputLabel id="demo-simple-select-label">Select Survey Module</InputLabel>
              <Select
                disabled={isFileReading || isDataUploading}
                required
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formValues.surveyModuleName}
                onChange={(event) => {
                  handleFormChange('surveyModuleName', event.target.value?.toString() ?? '');
                }}
                label="Select Survey Module"
              >
                <MenuItem value={'CU'}>Channel Usage</MenuItem>
                <MenuItem value={'CS'}>Channel Satisfaction</MenuItem>
                <MenuItem value={'NPS'}>Net Promotor Score</MenuItem>
                <MenuItem value={'DNPS'}>Drivers of NPS</MenuItem>
                {/* <MenuItem value={'PO'}>Product Ownership</MenuItem> */}
              </Select>
            </FormControl>
          )}
          {(formValues.clientName !== '' || formValues.surveyModuleName !== '') && (
            <FileUpload formValues={formValues} handleFileChange={handleFileChange} />
          )}
          {fileValidation !== null && (
            <Typography
              sx={{
                color:
                  fileValidation.type === 'error'
                    ? 'error.main'
                    : fileValidation.type === 'warning'
                      ? 'orange'
                      : 'green',
                marginTop: '0.5rem',
                fontWeight: 'bolder',
              }}
            >
              {fileValidation.errorMessage}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          {!isFileReading && !isDataUploading && (
            <Button
              color="error"
              variant="text"
              onClick={() => {
                setIsConfDialogOpen(false);
                setFileValidation(null);
                setFormValues({
                  dataType: null,
                  clientName: '',
                  surveyModuleName: '',
                  file: null,
                });
              }}
            >
              Close
            </Button>
          )}
          <Button
            sx={{ color: '#00796b' }}
            onClick={() => {
              void saveUploadedData();
            }}
            disabled={isFileReading}
          >
            {isFileReading
              ? 'Loading file... Please wait'
              : isDataUploading
                ? 'Saving to the database, this can take few minutes..'
                : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
      {isAllWorkspacesLoading ||
      isAllWorkspacesFetching ||
      isDataUploading ||
      isWorkSpaceListLoading ||
      isWorkSpaceListFetching ? (
        <Backdrop open sx={{ zIndex: 1000 }}>
          <AppCircularProgress />
        </Backdrop>
      ) : null}
    </Fragment>
  );
};

export default ChatBox;
