import {
  type AddCommentToThreadRequest,
  type CommentThread,
  type CreateCommentThreadRequest,
  type UpdateCommentThreadRequest,
} from '../../types/CommentThread';
import { type DataTable, type DataTableRequest, type DataTableResponse } from '../../types/DataTable';
import { type Issue, type IssuesRequest } from '../../types/Issue';
import { type Module } from '../../types/Module';
import { type Submodule, type SubmoduleWithDescription } from '../../types/SubModule';
import { type SubmoduleAssign } from '../../types/SubmoduleAssign';
import { type ValidationRequest, type ValidationResponse } from '../../types/Validation';
import { rebexApi } from './baseRebexEndpoints';
import { type DataTableValues } from '../../../utils/types/DataTablesEnum';

export const submodulesExtendedApi = rebexApi.injectEndpoints({
  endpoints: (builder) => ({
    getSubmodulesByModule: builder.query<Module, number>({
      query: (moduleId: number) => `/submodules/module/${moduleId}`,
    }),
    getSubmoduleBySubmoduleId: builder.query<SubmoduleWithDescription, number>({
      query: (submoduleId: number) => `/submodules/${submoduleId}`,
    }),
    insertDataTable: builder.mutation<DataTable, Partial<DataTableRequest>>({
      query: (dataTable) => ({
        url: `/submodules/${dataTable.submodule_id}/tables?table=${dataTable.table}`,
        method: 'POST',
        body: dataTable,
      }),
    }),
    updateDataTable: builder.mutation<DataTable, DataTableRequest>({
      query: ({ submodule_id: submoduleId, table, ...body }) => ({
        url: `/submodules/${submoduleId}/data-tables?table=${table}`,
        method: 'PUT',
        body,
      }),
    }),
    getDataTable: builder.query<DataTableResponse, { submodule_id: number; table: DataTableValues; version?: number }>(
      {
        query: (dataTable) => ({
          url: `/submodules/${dataTable.submodule_id}/data-tables?table=${dataTable.table}`,
        }),
        serializeQueryArgs: ({ queryArgs }) => {
          return `${queryArgs.submodule_id}-${queryArgs.table}-${queryArgs.version ?? 0}`;
        },
      },
    ),
    getAllCommentThreadsByDataTable: builder.query<CommentThread[], number>({
      query: (dataTableId: number) => `/submodules/data-tables/${dataTableId}/comment-threads`,
    }),
    createCommentThread: builder.mutation<CommentThread, CreateCommentThreadRequest>({
      query: (request: CreateCommentThreadRequest) => {
        const { dataTableId, ...body } = request;
        return {
          url: `/submodules/data-tables/${dataTableId}/comment-threads`,
          method: 'POST',
          body,
        };
      },
    }),
    addCommentToThread: builder.mutation<CommentThread, AddCommentToThreadRequest>({
      query: (request: AddCommentToThreadRequest) => {
        const { commentThreadId, ...body } = request;
        return {
          url: `/submodules/comment-threads/${commentThreadId}`,
          method: 'POST',
          body,
        };
      },
    }),
    updateCommentThread: builder.mutation<CommentThread, UpdateCommentThreadRequest>({
      query: (request: UpdateCommentThreadRequest) => {
        const { commentThreadId, ...body } = request;
        return {
          url: `/submodules/comment-threads/${commentThreadId}`,
          method: 'PUT',
          body,
        };
      },
    }),
    getIssues: builder.query<Issue[], IssuesRequest>({
      query: (request) => {
        const { dataTableId, submoduleId } = request;
        return {
          url: `/submodules/${submoduleId}/data-tables/${dataTableId}/issues`,
        };
      },
    }),
    triggerValidation: builder.mutation<ValidationResponse, ValidationRequest>({
      query: (body) => {
        return {
          url: `/submodules/trigger-validation`,
          method: 'POST',
          body,
        };
      },
    }),
    assignDataCollector: builder.mutation<null, SubmoduleAssign[]>({
      query: (body) => {
        return {
          url: `/submodules/assign-user`,
          method: 'POST',
          body,
        };
      },
    }),
    getUserSubmodules: builder.query<Submodule[], number>({
      query: (workspaceId) => ({
        url: '/submodules/users',
        params: {
          workspace_id: workspaceId,
        },
      }),
    }),
  }),
});
